// TODO: convert to a hook so we don't have to pass in user and flags
// https://palmetto.atlassian.net/browse/PA-3157

import { Property } from 'api/properties/types';
import { User, UserSource } from 'api/user/types';
import { LDFlags } from 'config';
import { AssociatedEntityTypes } from 'core/associatedEntity';
import { doesStateHaveUtility } from 'utils/permittedUtilityProviders';
import { checkAccessFromSharedContentToken } from 'utils/sharedContent';

export type IFeatureConfig = (user?: User, flags?: LDFlags, property?: Property) => boolean;

export type FeatureConfigGroup = {
  [key: string]: ({ user, flags, property }: { user?: User; flags?: LDFlags; property?: Property }) => boolean;
};

const siteSurvey: IFeatureConfig = (user: User | undefined, flags: LDFlags | undefined) => {
  // show for any authenticated user, otherise check for site-survey token
  return Boolean(user) || checkAccessFromSharedContentToken('site-survey');
};

const service: IFeatureConfig = (user?: User) => Boolean(user && user.segmentation?.solarSystemCount);

const referAFriend: IFeatureConfig = (user?: User, flags?: LDFlags) => {
  return (
    Boolean(flags?.referAFriend) &&
    Boolean(!user?.associatedEntities?.some((e) => e.entityType === AssociatedEntityTypes.sabalOrganization)) &&
    Boolean(user && user.source !== UserSource.luminance)
  );
};

const energyAdvisorPage: IFeatureConfig = (user, flags, property) => {
  return (
    (Boolean(flags?.energyAdvisorPage) &&
      Boolean(user?.associatedEntities?.find((e) => e.entityType === AssociatedEntityTypes.luminanceEstimate))) ||
    (Boolean(flags?.energyAdvisorV2) && Boolean(property?.hasEnergyAdvisorData))
  );
};

const financeAccountDetail: IFeatureConfig = (user?: User) => {
  return Boolean(user && user.financeAccounts?.length);
};

const dashboard: IFeatureConfig = (user?: User) => Boolean(user && user.source !== UserSource.luminance);

const gridUsageChart: IFeatureConfig = (user, flags, property) => {
  return Boolean(
    Boolean(user?.segmentation?.solarSystemCount) && property?.hasGridConnection && flags?.displayGridUsageChart,
  );
};

const energyProfile: IFeatureConfig = (_, flags?: LDFlags) => Boolean(flags?.energyProfile);

const utilityProvider: IFeatureConfig = (user?, flags?, property?) => {
  return Boolean(
    flags?.utilityProvider &&
      !property?.hasGridConnection &&
      doesStateHaveUtility({ stateCode: user?.stateCode, flags }),
  );
};

const showProjectsExperience: IFeatureConfig = (_, flags?: LDFlags) => Boolean(flags?.showProjectsExperience);

const featuredItems: FeatureConfigGroup = {
  'heat-pump-upgrade': ({ property }: { property?: Property }) => {
    return Boolean(property?.address.state === 'MA');
  },
  'stand-alone-storage': ({ property }: { property?: Property }) => {
    const allowedStates = new Set(['GA', 'NC', 'SC', 'VA', 'MD', 'IL']);
    return property ? Boolean(allowedStates.has(property?.address.state)) : false;
  },
};

export default {
  siteSurvey,
  service,
  referAFriend,
  energyAdvisorPage,
  financeAccountDetail,
  dashboard,
  gridUsageChart,
  energyProfile,
  utilityProvider,
  showProjectsExperience,
  featuredItems,
};
